import React from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
    Typography,
    Box,
    Grid,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';
import thumbsUpIcon from '../../../../assets/images/thumsup.png';
import VehiclesRadio from './VehiclesRadio';
import PlateformRadio from './PlateformRadio';
import FinalizedRadio from './FinalizedRadio';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        '& .Credit-MuiFormControlLabel-label': {
            fontSize: 12
        },
        '& .Credit-MuiFormControlLabel-root:last-child': {
            marginRight: 0
        },
        '& .Credit-MuiFormControlLabel-root': {
            marginRight: 12
        }
    },
    modalContent: {
        flexGrow: 1
    },
    radioGroup: {
        display: 'inline-block',
    },
    list: {
        padding: theme.spacing(0)
    },
    items: {
        display: 'flex',
        padding: theme.spacing(0)
    },
    lsItemText: {
        display: 'flex',
        '& > span': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    pdText: {
        marginLeft: theme.spacing(0.75),
        fontSize: theme.spacing(1.5),
        lineHeight: 1.3,
        '&:focus': {
            outline: 'none'
        },
        '& br': {
            [theme.breakpoints.down(767)]: {
                display: 'none'
            }
        }
    }
}));


export default function ModalContent(props) {
    const { t, i18n } = useTranslation('app');
    const productDetailsList = [
        {
            listIcon: 'https://cdn2.droom.in/imgusr/droomCredit/v2/benefit-ico1.png',
            listIconText: 'Loan Decision In 30 Seconds',
            // eslint-disable-next-line
            listText: 'Loan Decision In 30 <br \/> Seconds',
            translatedText: t('req-model-Loan Decision In', 'Loan Decision In') + " <br\/> " + t('home-30 Seconds', '30 Seconds')
        },
        {
            listIcon: 'https://cdn2.droom.in/imgusr/droomCredit/v2/benefit-ico2.png',
            listIconText: 'Hassle Free & Paperless',
            // eslint-disable-next-line
            listText: 'Hassle Free & <br \/> Paperless',
            translatedText: t('req-model-Hassle Free &', 'Hassle Free &') + " <br\/> " + t('req-model-Paperless', 'Paperless')
        },
        {
            listIcon: 'https://cdn2.droom.in/imgusr/droomCredit/v2/benefit-ico3.png',
            listIconText: 'Unbeatable Loan Terms',
            // eslint-disable-next-line
            listText: 'Unbeatable Loan <br \/> Terms',
            translatedText: t('req-model-Unbeatable Loan', 'Unbeatable Loan') + " <br\/> " + t('req-model-Terms', 'Terms')
        },
        {
            listIcon: 'https://cdn2.droom.in/imgusr/droomCredit/v2/benefit-ico4.png',
            listIconText: 'Real Time Quotes From Multiple Lenders',
            // eslint-disable-next-line
            listText: 'Real Time Quotes From <br \/> Multiple Lenders',
            translatedText: t('req-Real Time Quotes', 'Real Time Quotes') + " <br\/> " + t('req-model-From Multiple Lenders', 'From Multiple Lenders')
        }
    ];
    const classes = useStyles();
    return (
        <div className={classes.modalContent}>
            <Box align="center" variant="head" component="div" my={4}>
                <img src={thumbsUpIcon} alt="Thank you" />
                <Typography component="h3" variant="h5">
                    {t('req-model-Thank You', 'Thank You')}!
        </Typography>
                <Typography component="h6" variant="subtitle2">
                    {t('req-model-For Submitting Your Credit Requirements', 'For Submitting Your Credit Requirements')}
                </Typography>
            </Box>
            <Grid container className={classes.root} spacing={3}>
                {productDetailsList.map((lsItems, index) => (
                    <Grid item xs={6} sm={6} key={index}>
                        <List className={classes.list}>
                            <ListItem className={classes.items}>
                                <ListItemText color="inherit" className={classes.lsItemText}>
                                    <img
                                        src={lsItems.listIcon}
                                        alt={lsItems.listIconText}
                                        className={classes.pdIcons}
                                    />
                                    <Typography
                                        className={classes.pdText}
                                        dangerouslySetInnerHTML={{ __html: lsItems.translatedText }}
                                    />
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Grid>
                ))}
                <Grid item xs={12} sm={12}>
                    <div className={classes.radioGroup}>
                        <VehiclesRadio required={props.required} />
                        <PlateformRadio required={props.required} />
                        <FinalizedRadio required={props.required} />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

ModalContent.propTypes = {
    required: PropTypes.object.isRequired
}
