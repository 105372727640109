import React from 'react'
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Grid, Typography, Link } from '@material-ui/core';
import { TextField } from 'mui-rff';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form'
import { service } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { getLocalizedPath } from '../../../i18n/functions';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import CreateValidateYupSchema from '../../../components/YupSchema/CreateValidateYupSchema';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        [theme.breakpoints.down(767)]: {
            padding: theme.spacing(2, 0)
        }      
    },
    dialogTitle: {
        marginTop: 20
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    button: {
        display: 'flex',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 14,
        marginTop: 10,
        width: '100%',
        padding: '5px 20px',
        border: '1px solid',
        color: 'white',
        backgroundColor: '#a3be28',
        borderColor: '#96b11a',
        '&:hover': {
            backgroundColor: '#96b11a',
            borderColor: '#96b11a',
            boxShadow: 'none'
        },
        [theme.breakpoints.down(767)]: {
            display: 'table',
            margin: theme.spacing(2.5, 'auto', 0),
            width: '100%',
            maxWidth: '100%'
        },
        [theme.breakpoints.down(551)]: {
            maxWidth: '100%',
            padding: theme.spacing(1, 0)
        }
    },
    checkedIcon: {
        backgroundColor: '#a3be28',
        backgroundImage:
            'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""'
        },
        'input:hover ~ &': {
            backgroundColor: '#a3be28'
        }
    }
}));


const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(0, 2),
        minHeight: '100%',
        overflowY: 'initial'
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: theme.spacing(0, 0, 2),
        padding: theme.spacing(1),
        justifyContent: 'center'
    }
}))(MuiDialogActions);

const validateYupSchema = CreateValidateYupSchema([
    'otp'
]);
const required = validateYupSchema.required;


export default function OtpModal(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('480'));
    const classes = useStyles();

    const { t, i18n } = useTranslation('app');

    const [alertSeverity, setAlertSeverity] = React.useState("error");

    const [resendOtp, setResendOtp] = React.useState(false);
    const [resendOtpMessage, setResendOtpMessage] = React.useState('');

    const handleResendClick = async () => {
        setResendOtp(true);
        let submitError = {};
        await service.post(getLocalizedPath('lead-user-verification', i18n), {
            'name': 'send_requirement_lead_otp',
            'value': props.mobile
        })
        .then(response => {
            if (response.data.code == 'success') {
                setAlertSeverity('success');
                setResendOtpMessage("Otp sent");
                setResendOtp(false);
            }
        })
        .catch(err => {
            console.log(err);
            setAlertSeverity('error');
            setResendOtpMessage("Something went wrong.");
            setResendOtp(false);
        });

        setTimeout(() => {
            setResendOtpMessage('');
        }, 2000);
    };

    const onSubmit = async values => {
        let submitError = {};
        await service.post(getLocalizedPath('lead-user-verification', i18n), {
            'name': 'verify_requirement_lead_otp',
            'value': values.otp
        })
            .then((response) => {
                const data = response.data
                if (data.code == 'success') {
                    props.handleCloseOtp();
                    props.handleClickOpenModal();
                } else {
                    submitError = data.message
                }
            })
            .catch(err => {
                console.log(err);
                submitError = "Something went wrong.";
            });
        return { [FORM_ERROR]: submitError };
    }


    return (
        <div className={classes.root}>
            <Dialog
                fullScreen={fullScreen}
                onClose={props.handleCloseOtp}
                aria-labelledby="customized-dialog-title-new"
                open={props.openOtp}
                maxWidth={props.maxWidth}
            >
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={props.handleCloseOtp}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle variant="h5" component="h4" className={classes.dialogTitle}>Phone Number Verification</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" component="div">{t('otp-verification-We have sent an OTP to your mobile number', 'We have sent an OTP to your mobile number')} +91 {props.mobile} {t('otp-verification-for verification', 'otp-verification-for verification.')}</Typography>
                    <Form
                        onSubmit={onSubmit}
                        initialValues={{}}
                        validate={validateYupSchema.validate}
                        render={({ handleSubmit, submitting, submitError, touched }) => (
                            <form className={classes.root} onSubmit={handleSubmit} noValidate>
                                <Grid container>
                                    <Grid item xs={6} sm={7}>
                                        <TextField
                                            autoFocus={true}
                                            margin="dense"
                                            name="otp"
                                            id="otp"
                                            label="OTP"
                                            type="Number"
                                            fullWidth
                                            required={required.otp}
                                        />                                        
                                        <Typography component="span">
                                            <Link style={{fontSize: 12}} onClick={handleResendClick}>
                                            Resend OTP
                                        </Link>
                                        {resendOtp && <CircularProgress className={classes.progressButtton} size={15}/> }
                                        {resendOtpMessage && !submitting && <Alert severity={alertSeverity}>{resendOtpMessage}</Alert>}
                                        </Typography>
                                        
                                    </Grid>
                                    <Grid item xs={1} sm={1}></Grid>
                                    <Grid item xs={5} sm={4}>
                                        <DialogActions>
                                            <Button className={classes.button} type="submit" color="primary" disabled={submitting || resendOtp}>{t('Submit', 'Submit')}</Button>
                                        </DialogActions>
                                    </Grid>
                                </Grid>
                                {submitError && touched && (
                                    <Alert severity="error">{submitError}</Alert>
                                )}
                            </form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </div>
    )
}

OtpModal.propTypes = {
    openOtp: PropTypes.bool.isRequired,
    handleCloseOtp: PropTypes.func.isRequired,
    mobile: PropTypes.string.isRequired,
    handleClickOpenModal: PropTypes.func.isRequired
}
