import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import carLoanIcon from '../../../../assets/images/carloan.png';
import bikeLoanIcon from '../../../../assets/images/bike.png';
import scooterLoanIcon from '../../../../assets/images/scooter.png';
import dealerLoanIcon from '../../../../assets/images/dealer-image.png';
import calculatorIcon from '../../../../assets/images/calculator.png';
import scoreIcon from '../../../../assets/images/score.png';
import { getLocalizedPath } from '../../../i18n/functions';

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        flexGrow: 1,
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginTop: theme.spacing(1.5)
        }
    },
    button: {
        position: 'relative',
        padding: theme.spacing(1.25, 3),
        margin: theme.spacing(0, 2, 2.5, 0),
        fontWeight: 600,
        fontSize: 16,
        textTransform: 'none',
        transition: 'all 0.5s',
        borderRadius: theme.spacing(2),
        '&:hover': {
            backgroundColor: '#a9d2ca'
        },
        [theme.breakpoints.down('xs')]: {
            margin: '0px 0 16px 4%',
            padding: theme.spacing(1, 1.25),
            width: '48%',
            lineHeight: 1,
            textAlign: 'left',
            '&:first-child': {
                marginLeft: 0
            },
            '&:nth-child(03)': {
                display: 'table',
                margin: '0 auto',
                textAlign: 'left',
            },
            fontSize: theme.spacing(1.5),
            borderRadius: theme.spacing(1.25),
            '& .Credit-MuiButton-label': {
                justifyContent: 'start'
            }
        }
    },
    linkButton: {
        background:'#fff6b4'
    },
    buttonIconBody: {
        display: 'inline-block',
        width: 50,
        height: 50,
        marginRight: theme.spacing(1),
        borderRadius: theme.spacing(2),
        verticalAlign: 'middle',
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(4.5),
            height: theme.spacing(4.5),
            borderRadius: theme.spacing(1.25),
            textAlign: 'center',
            '& img': {
                width: theme.spacing(3)
            }
        }
    },
    restButtons: {
        marginTop: theme.spacing(2.5),
        '& button:last-child': {
            paddingRight: theme.spacing(6)
        },
        [theme.breakpoints.down('xs')]: {
            flexGrow: 1,
            '& button:first-child': {
                '& img': {
                    width: theme.spacing(2)
                }
            },
            '& button:last-child': {
                paddingRight: theme.spacing(0),
                lineHeight: 1
            }
        }
    },
    badge: {
        position: 'absolute',
        right: -15,
        top: '-30px',
        color: '#fff',
        width: 52,
        height: 54,
        textTransform: 'uppercase',
        '& img': {
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 1
        },
        [theme.breakpoints.down('xs')]: {
            right: theme.spacing(0),
            top: theme.spacing(-4),
            width: 40,
            height: 42,
            '& img': { width: theme.spacing(5) }
        }
    },
    badgeText: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: theme.spacing(1.50),
        zIndex: 2,
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.spacing(1.25)
        }
    }
}));

export default function ActionButtons(props) {
    const { t, i18n } = useTranslation(['app', 'common']);
    const classes = useStyles();
    return (
        <div className={classes.buttonContainer}>
            <Button variant="contained" color="secondary" className={classes.button} onClick={event => props.handleActionButtonClick('car')} >
                {' '}
                <span
                    className={classes.buttonIconBody}
                    style={{ backgroundColor: '#fff8dd' }}
                >
                    <img
                        src={carLoanIcon}
                        alt="Droom Credit | Car Loan"
                        style={{ marginTop: 6 }}
                    />
                </span>
                {t('Car Loan', 'Car Loan')}
            </Button>
            <Button variant="contained" color="secondary" className={classes.button} onClick={event => props.handleActionButtonClick('motorcycle/bike')} >
                {' '}
                <span
                    className={classes.buttonIconBody}
                    style={{ backgroundColor: '#fae0e2' }}
                >
                    <img
                        src={bikeLoanIcon}
                        alt="Droom Credit | Bike Loan"
                        style={{ marginTop: 15 }}
                    />
                </span>
                {t('Bike Loan', 'Bike Loan')}
            </Button>
            <Button variant="contained" color="secondary" className={classes.button} onClick={event => props.handleActionButtonClick('scooter')} >
                {' '}
                <span
                    className={classes.buttonIconBody}
                    style={{ backgroundColor: '#ffe0d3' }}
                >
                    <img
                        src={scooterLoanIcon}
                        alt="Droom Credit | Scooter Loan"
                        style={{ marginTop: 7 }}
                    />
                </span>
                {t('Scooter Loan', 'Scooter Loan')}
            </Button>
            <Button variant="contained" color="secondary" className={classes.button} onClick={event => props.handleActionButtonClick('scooter')} >
                {' '}
                <span
                    className={classes.buttonIconBody}
                    style={{ backgroundColor: '#ffe0d3' }}
                >
                    <img
                        src={dealerLoanIcon}
                        alt="Droom Credit | Scooter Loan"
                        style={{ marginTop: 7 }}
                    />
                </span>
                {t('Dealer Financing', 'Dealer Financing')}
            </Button>
            <div className={classes.restButtons}>
                <Button
                    href={getLocalizedPath('emi-calculator', i18n)}
                    variant="contained"
                    color="secondary"
                    className={clsx(classes.button, classes.linkButton)}
                >
                    {' '}
                    <span
                        className={classes.buttonIconBody}
                        style={{ backgroundColor: '#fff' }}
                    >
                        <img
                            src={calculatorIcon}
                            alt="EMI Calculator"
                            style={{ marginTop: 8, marginLeft: 12 }}
                            className={classes.calcIcon}
                        />
                    </span>
                    {t('EMI Calculator', 'EMI Calculator')}
                </Button>
                <Button
                    href={getLocalizedPath('credit-score-assessment', i18n)}
                    variant="contained"
                    color="secondary"
                    className={clsx(classes.button, classes.linkButton)}
                >
                    {' '}
                    <span
                        className={classes.buttonIconBody}
                        style={{ backgroundColor: '#fff' }}
                    >
                        <img
                            src={scoreIcon}
                            alt="Get Credit Score"
                            style={{ marginTop: 12, marginLeft: 8 }}
                        />
                    </span>
                    <span className={classes.badge}>
                        <img
                            src="https://cdn2.droom.in/imgusr/droomCredit/v2/tag.png"
                            alt="Free"
                        />
                        <Typography className={classes.badgeText}>{t('home-Free', 'Free')}</Typography>
                    </span>
                    {t('common:Get Credit score', 'Get Credit Score')}
                </Button>
            </div>
        </div>
    );
}

ActionButtons.propTypes = {
    handleActionButtonClick: PropTypes.func.isRequired
};
