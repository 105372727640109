import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary
} from './AccordionMuiTheme';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import PrimaryTitles from './PrimaryTitle';
import { useTranslation } from 'react-i18next';
import { List, ListItemText, ListItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    faqMainDiv: {
        padding: theme.spacing(3),
        '& h6': {
            fontSize: '15px',
            fontWeight: 600,
            color: '#4e4e4e'
        },
        '& li': {
            display: 'list-item',
            marginLeft: 15,
            paddingLeft: 5,
            paddingBottom: 0,
            listStyle: 'decimal'
        },
        '& li>div>span': {
            fontSize: 14,
        }

    }
}));
const ReadMoreButton = withStyles({
    root: {
        boxShadow: 'none',
        padding: 0,
        textTransform: 'none',
        fontSize: 14,
        borderColor: '#0063cc',
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none'
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: 'transparent'
        },
        '&:focus': {
            boxShadow: 'none',
        },
    },
})(Button);

export default function Faqs() {
    const { t, i18n } = useTranslation(['app', 'common']);
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const classes = useStyles();

    const [readMoreAbout, setReadMoreAbout] = useState(false);
    const [readMoreBorrower, setReadMoreBorrower] = useState(false);
    const [readMoreLender, setReadMoreLender] = useState(false);

    const aboutExtraContent = <div classes={classes.points}>
        <Typography variant="h6" gutterBottom>{t('faq_h1_q2')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h1_q2_p1')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h1_q3')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h1_q3_p1')}</Typography>
        <List>
            <ListItem>
                <ListItemText>{t('faq_h1_q3_p2')}</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>{t('faq_h1_q3_p3')}</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>{t('faq_h1_q3_p4')}</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>{t('faq_h1_q3_p5')}</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>{t('faq_h1_q3_p6')}</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>{t('faq_h1_q3_p7')}</ListItemText>
            </ListItem>
        </List>
        <Typography variant="h6" gutterBottom>{t('faq_h1_q4')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h1_q4_p1')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h1_q5')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h1_q5_p1')}</Typography>
    </div>
    const extraContentBorrower = <div classes={classes.points}>
        <Typography variant="body2" gutterBottom><span>Step1:</span> {t('faq_h2_q2_p3')}</Typography>
        <Typography variant="body2" gutterBottom><span>Step2:</span> {t('faq_h2_q2_p5')}</Typography>
        <Typography variant="body2" gutterBottom><span>Step3:</span> {t('faq_h2_q2_p7')}</Typography>
        <Typography variant="body2" gutterBottom><span>Step4:</span> {t('faq_h2_q2_p9')}</Typography>
        <Typography variant="body2" gutterBottom><span>Step5:</span> {t('faq_h2_q2_p11')}</Typography>
        <Typography variant="body2" gutterBottom><span>Step6:</span> {t('faq_h2_q2_p13')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h2_q3')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h2_q3_p1')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h2_q3_p2')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h2_q4')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h2_q4_p1')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h2_q4_p2')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h2_q5')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h2_q5_p1')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h2_q6')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h2_q6_p1')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h2_q7')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h2_q7_p1')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h2_q7_p2')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h2_q8')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h2_q8_p1')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h2_q9')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h2_q9_p1')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h2_q10')}</Typography>
        <Typography variant="body2" gutterBottom><span>Please contact us at support@droomcredit.com for any queries.</span></Typography>
        <Typography variant="h6" gutterBottom>{t('car-loan-faqs-q2')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h2_q11_p1')}</Typography>
        <List>
            <ListItem>
                <ListItemText>{t('faq_h2_q11_p2')}</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>{t('faq_h2_q11_p3')}</ListItemText>
            </ListItem>
        </List>
        <Typography variant="body2" gutterBottom>{t('faq_h2_q11_p4')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h2_q12')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h2_q12_p1')}<sup>st</sup> {t('faq_h2_q12_p2')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h2_q13')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h2_q13_p1')}</Typography>
    </div>
    const extraContentLender = <div classes={classes.points}>
        <Typography variant="h6" gutterBottom>{t('faq_h3_q3')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h3_q3_p1')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h3_q4')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h3_q4_p1')}</Typography>
        <List>
            <ListItem>
                <ListItemText>{t('faq_h3_q4_p2')}</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>{t('faq_h3_q4_p3')}</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>{t('faq_h3_q4_p4')}</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>{t('faq_h3_q4_p5')}</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>{t('faq_h3_q4_p6')}</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>{t('faq_h3_q4_p7')}</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>{t('faq_h3_q4_p8')}</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>{t('faq_h3_q4_p9')}</ListItemText>
            </ListItem>
        </List>
        <Typography variant="h6" gutterBottom>{t('faq_h3_q5')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h3_q5_p1')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h3_q6')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h3_q6_p1')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h3_q7')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h3_q7_p1')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h3_q8')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h3_q8_p1')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h3_q9')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h3_q9_p1')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h3_q10')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h3_q10_p1')}</Typography>
        <Typography variant="h6" gutterBottom>{t('faq_h3_q11')}</Typography>
        <Typography variant="body2" gutterBottom>{t('faq_h3_q11_p1')}</Typography>

    </div>
    const linkNameAbout = readMoreAbout ? `${t('common:Read Less', 'Read Less')}..` : `${t('common:Read More', 'Read More')}..`
    const linkNameBorrower = readMoreBorrower ? `${t('common:Read Less', 'Read Less')}..` : `${t('common:Read More', 'Read More')}..`
    const linkNameLender = readMoreLender ? `${t('common:Read Less', 'Read Less')}..` : `${t('common:Read More', 'Read More')}..`

    return (
        <div className={classes.faqMainDiv}>
            <div className="container">
                <PrimaryTitles name="FAQs" />
                <Accordion
                    expanded={expanded === 'panel1'}
                    onChange={handleChange('panel1')}
                >
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                    >
                        <Typography component="h3" variant="subtitle1">
                            {t('About Droom Credit', 'About Droom Credit')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2" gutterBottom>
                            {t('faq_h1_q1_p1')}
                        </Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                        <Typography variant="body2" gutterBottom>
                            {t('faq_h1_q1_p2')}<sup>st</sup>{t('faq_h1_q1_p3')}
                        </Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                        {readMoreAbout && aboutExtraContent}
                    </AccordionDetails>
                    <AccordionDetails>
                        <ReadMoreButton disableRipple onClick={() => { setReadMoreAbout(!readMoreAbout) }}>{t('common:Read More', 'Read Less'), linkNameAbout}
                        </ReadMoreButton>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={expanded === 'panel2'}
                    onChange={handleChange('panel2')}
                >
                    <AccordionSummary
                        className={classes.arrowIcon}
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                    >
                        <Typography component="h3" variant="subtitle1">
                            {t('common:About Borrowers', 'About Borrowers')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="h6" gutterBottom>{t('faq_h2_q1')}</Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                        <Typography variant="body2" gutterBottom>{t('faq_h2_q1_p1')}</Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                        <Typography variant="h6" gutterBottom>{t('faq_h2_q2')}</Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                        <Typography variant="body2" gutterBottom>{t('faq_h2_q2_p1')}</Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                        <Typography variant="body2" gutterBottom>{t('faq_h2_q2_p2')}</Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                        {readMoreBorrower && extraContentBorrower}
                    </AccordionDetails>
                    <AccordionDetails>
                        <ReadMoreButton disableRipple onClick={() => { setReadMoreBorrower(!readMoreBorrower) }}>{t('common:Read More', 'Read More'), linkNameBorrower}
                        </ReadMoreButton>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={expanded === 'panel3'}
                    onChange={handleChange('panel3')}
                >
                    <AccordionSummary
                        className={classes.arrowIcon}
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                    >
                        <Typography component="h3" variant="subtitle1">
                            {t('common:About Lenders', 'About Lenders')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="h6" gutterBottom>{t('credit-score-What Is Droom Credit Score?')}</Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                        <Typography variant="body2" gutterBottom>{t('faq_h1_q1_p1')}</Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                        <Typography variant="body2" gutterBottom>{t('faq_h1_q1_p2')}<sup>st</sup> {t('faq_h1_q1_p3')}</Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                        <Typography variant="h6" gutterBottom>{t('faq_h3_q2')}</Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                        <Typography variant="body2" gutterBottom>{t('faq_h3_q2_p1')}</Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                        {readMoreLender && extraContentLender}
                    </AccordionDetails>
                    <AccordionDetails>
                        <ReadMoreButton disableRipple onClick={() => { setReadMoreLender(!readMoreLender) }}>{t('common:Read More', 'Read Less'), linkNameLender}
                        </ReadMoreButton>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
}
