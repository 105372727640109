import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { TextField } from 'mui-rff';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import RadioButtons from './RadioButtons';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form'
import CreateValidateYupSchema from '../../../components/YupSchema/CreateValidateYupSchema';
import Modal from '../../Modal/Home/Modal';
import OtpModal from '../../Modal/Home/OtpModal';
import { service } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { getLocalizedPath } from '../../../i18n/functions';
import Alert from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            '& .Credit-MuiFormControl-root': {
                marginTop: theme.spacing(2),
                marginRight: theme.spacing(4),
                width: '48ch',
                [theme.breakpoints.down('xs')]: {
                    width: '100%',
                    marginRight: theme.spacing(0)
                }
            },
            '& .Credit-MuiFormLabel-root': {
                color: 'rgba(0, 0, 0, 0.50)',
                fontSize: theme.spacing(1.8)
            },
            '& .Credit-MuiInput-underline:before': {
                borderBottom: '1px solid #ddd'
            },
            '& .Credit-MuiInput-underline:after': {
                borderBottom: '1px solid #a3be28'
            },
            '& .Credit-MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.50)'
            },
            '& .Credit-MuiTypography-root': {
                fontSize: theme.spacing(1.8)
            }
        },
        icon: {
            display: 'inline-block',
            borderRadius: '50%',
            width: 16,
            height: 16,
            boxShadow:
                'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            backgroundColor: '#f5f8fa',
            backgroundImage:
                'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2
            },
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5'
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)'
            }
        },
        checkedIcon: {
            backgroundColor: '#a3be28',
            backgroundImage:
                'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 16,
                height: 16,
                backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
                content: '""'
            },
            'input:hover ~ &': {
                backgroundColor: '#a3be28'
            }
        },
        button: {
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 14,
            marginTop: 25,
            padding: '8px 7%',
            border: '1px solid',
            color: 'white',
            backgroundColor: '#a3be28',
            borderColor: '#96b11a',
            '&:hover': {
                backgroundColor: '#96b11a',
                borderColor: '#96b11a',
                boxShadow: 'none'
            },
            [theme.breakpoints.down(767)]: {
                display: 'table',
                margin: theme.spacing(2.5, 'auto', 0),
                width: '100%',
                maxWidth: '190px'
            }
        }
    })
);

export default function RequirementForm(props) {
    const { t, i18n } = useTranslation('app');
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // OTP modal
    const [openOtp, setOpenOtp] = React.useState(false);
    const handleClickOtpOpen = () => {
        setOpenOtp(true);
    };
    const handleCloseOtp = () => {
        setOpenOtp(false);
    }
    // Modal Max Width
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const handleMaxWidthChange = () => {
        setMaxWidth(this.value);
    }
    //end OTP modal

    const [redirectToLogin, setRedirectToLogin] = React.useState(false);

    React.useEffect(() => {
        if (redirectToLogin) {
            const timer = setTimeout(() => window.location.replace('/login'), 1500);
            return () => clearTimeout(timer);
        }
    }, [redirectToLogin]);

    const [initialValues, setInitialValues] = React.useState({
        condition: "used"
    });

    const [alertSeverity, setAlertSeverity] = React.useState("error");

    const isLoggedIn = useSelector(state => state.user.isLoggedIn);

    React.useEffect(() => {
        if(isLoggedIn === true){
            service.get(getLocalizedPath('get-requirement-lead-data', i18n))
                .then(response => {
                    if (Object.keys(response.data.data).length) {
                        setInitialValues(response.data.data);
                        if (!response.data.message && Object.keys(response.data.data).length == 4) {
                            setOpenOtp(true);
                        }
                    }
                })
                .catch(err => console.log(err));
        } else {
            setInitialValues({ condition: "used" });
        }
    }, [isLoggedIn]);

    const onSubmit = async values => {
        let submitError = {};
        let sendOtp = false;
        setInitialValues(values);

        //create lead
        await service.post(getLocalizedPath('create-requirement-lead', i18n), values)
            .then(response => {
                const data = response.data
                if (data.code == 'success') {
                    if (typeof data.data.userExists !== 'undefined' && data.data.userExists) {
                        submitError = 'Email already exists, please login to continue.';
                        setAlertSeverity("info");
                        setRedirectToLogin(true);
                    } else {
                        sendOtp = true;
                    }
                } else {
                    submitError = data.message
                }
            })
            .catch(err => {
                console.log(err);
                submitError = "Something went wrong.";
            });

        //send otp
        if (sendOtp === true) {
            await service.post(getLocalizedPath('lead-user-verification', i18n), {
                'name': 'send_requirement_lead_otp',
                'value': values.mobile
            })
                .then(response => {
                    if (response.data.code == 'success') {
                        setOpenOtp(true);
                    }
                })
                .catch(err => {
                    console.log(err);
                    submitError = "Something went wrong.";
                });
        }

        return { [FORM_ERROR]: submitError };
    }

    const validateYupSchema = CreateValidateYupSchema([
        'email',
        'fullName',
        'mobile',
        'condition'
    ]);

    const focusFirstName = () => {
        const fullNameInput = document.getElementById('fullName');
        return props.requirementFormFocus ? fullNameInput.focus() : '';
    }

    const handleOnBlur = () => {
        props.focusRequirementForm(false)
    }

    React.useEffect(() => {
        focusFirstName();
    }, [props.requirementFormFocus]);

    const required = validateYupSchema.required;
    return (
        <>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                validate={validateYupSchema.validate}
                render={({ handleSubmit, submitting, submitError, touched }) => (
                    <form className={classes.root} onSubmit={handleSubmit} noValidate>
                        <TextField id="fullName" label={t('Full Name', 'Full Name')} name="fullName" required={required.fullName} autoFocus={false} onBlur={handleOnBlur}/>
                        {
                            initialValues.email
                                ?
                                <TextField id="emailId" label={t('Email ID', 'Email ID')} name="email" required={required.email} InputProps={{
                                    readOnly: true,
                                }} value={initialValues.email} />
                                :
                                <TextField id="emailId" label={t('Email ID', 'Email ID')} name="email" required={required.email} />
                        }
                        <TextField id="phoneNumber" label={t('Mobile Number', 'Mobile Number')} name="mobile" required={required.mobile} type="number" onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                        }} />
                        <RadioButtons required={required} />
                        <Button className={classes.button} type="submit" variant="outlined" color="primary" disabled={submitting}>
                            {t('Submit', 'Submit')}
                        </Button>
                        {submitError && touched && (
                            <Alert severity={alertSeverity}>{submitError}</Alert>
                        )}
                    </form>
                )}
            />
            <OtpModal
                mobile={initialValues.mobile || ''}
                openOtp={openOtp}
                handleCloseOtp={handleCloseOtp}
                handleMaxWidthChange={handleMaxWidthChange}
                extra={true}
                handleClickOpenModal={handleClickOpen}
                maxWidth={maxWidth} />
            <Modal 
            open={open} 
            handleClickOpen={handleClickOpen} 
            handleClose={handleClose} 
            category={props.category} />
        </>
    );
}

RequirementForm.propTypes = {
    category: PropTypes.oneOfType([
        PropTypes.oneOf(['car', 'motorcycle/bike', 'scooter']),
        PropTypes.bool
    ]).isRequired,
    requirementFormFocus: PropTypes.bool.isRequired,
    focusRequirementForm: PropTypes.func.isRequired
}

