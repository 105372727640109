import * as Yup from 'yup';
import { makeValidate, makeRequired } from 'mui-rff';

const commonSchemaRules = (Rule) => {
    switch (Rule) {
        case 'email':
            return Yup
                .string()
                .required('Email is required')
                .email('Please enter a valid email');
            break;

        case 'mobile':
            return Yup
                .string()
                .required('Mobile number is required')
                .matches(/^[6-9]{1}[0-9]{9}$/, {
                    message: "Please enter a valid 10 digit mobile number",
                    excludeEmptyString: true
                });
            break;

        case 'condition':
            return Yup
                .string()
                .required('Vechicle condition is required')
                .matches(/^(used|new)$/, {
                    message: 'Please select a valid vehicle condition',
                    excludeEmptyString: true
                });
            break;

        case 'fullName':
            return Yup
                .string()
                .required('Full name is required')
                .matches(/^([a-zA-Z]+\s)+[a-zA-Z]+$/, {
                    message: "Please enter a valid full name",
                    excludeEmptyString: true
                });
            break;

        case 'vehicle':
            return Yup
                .string()
                .required('Vechicle type is required')
                .matches(/^(car|scooter|motorcycle\/bike)$/, {
                    message: 'Please select a valid vehicle type',
                    excludeEmptyString: true
                });
            break;

        case 'partner':
            return Yup
                .string()
                .required('Partner is required')
                .matches(/^(Droom|Other Website|Offline Dealer)$/, {
                    message: 'Please select a valid partner',
                    excludeEmptyString: true
                });
            break;

        case 'autoLoanType':
            return Yup
                .string()
                .required('Is vehicle finalized is required')
                .matches(/^(pre\-approval|legacy)$/, {
                    message: 'Please select a valid is vehicle finalized option',
                    excludeEmptyString: true
                });
            break;

        case 'otp':
            return Yup
                .string()
                .required('Otp is required')
                .matches(/[0-9]{6}/, {
                    message: 'Please enter a 6-digit otp',
                    excludeEmptyString: true
                });
            break;

        default:
            return false;
            break;
    }
}

const createValidationSchema = (commonRules, rest) => {

    const commonSchema = commonRules.reduce((result, rule) => {
        const value = commonSchemaRules(rule);
        if (value !== false) {
            result[rule] = value;
        }
        return result;
    }, {});

    return (Yup.object().shape({
        ...commonSchema,
        ...rest
    }));
}

const CreateValidateYupSchema = (commonRules, rest) => {
    const schema = createValidationSchema(commonRules, rest)
    return ({
        'validate': makeValidate(schema),
        'required': makeRequired(schema)
    });
};

export default CreateValidateYupSchema;