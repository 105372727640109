import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import PrimaryTitles from './PrimaryTitle';
import './CustomCarousel.css';
import { useTranslation } from 'react-i18next';
import OwlCarouselWrapper from './OwlCarouselWrapper';

const options = {
    items: 5,
    nav: true,
    loop: true,
    lazyLoad: true,
    autoplay: true,
    responsive: {
        0: {
            items: 1,
            margin: 20
        },
        400: {
            items: 2,
            margin: 20
        },
        600: {
            items: 3,
            margin: 20
        },
        700: {
            items: 3,
            margin: 20
        },
        1000: {
            items: 5
        }
    }
};
const owlItems = [
    {
        altText: 'Tata Capital',
        owlIcon:
            'https://cdn2.droom.in/photos/images/droomCredit/v2/tata-capital-new.png'
    },
    {
        altText: 'IDFC First Bank',
        owlIcon: 'https://cdn1.acedms.com/photos/images/droomCredit/v2/idfc.png'
    },
    {
        altText: 'Cashe',
        owlIcon: 'https://cdn1.acedms.com/photos/images/droomCredit/v2/cashe.png'
    },
    {
        altText: 'Deccan Finance',
        owlIcon:
            'https://cdn1.acedms.com/photos/images/droomCredit/v2/deccan-fin.png'
    },
    {
        altText: 'Lendbox',
        owlIcon: 'https://cdn1.acedms.com/photos/images/droomCredit/v2/lendbox.png'
    },
    {
        altText: 'Bajaj Finance',
        owlIcon: 'https://cdn1.acedms.com/photos/images/droomCredit/v2/bajaj.jpg'
    },
    {
        altText: 'Yes Bank',
        owlIcon: 'https://cdn1.acedms.com/photos/images/droomCredit/v2/yes-bank.png'
    }
];
export default function Partners() {
    const { t, i18n } = useTranslation('common');
    return (
        <div className="container">
            <PrimaryTitles name={t('Our Partners', 'Our Partners')}></PrimaryTitles>
            <OwlCarouselWrapper {...options} className="partnersCarousel">
                {owlItems.map((item, index) => (
                    <div key={index}>
                        <img alt={item.altText} className="img" src={item.owlIcon} />
                    </div>
                ))}
            </OwlCarouselWrapper>
        </div>
    );
}
