import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ModalContent from './ModalContent';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form'
import CreateValidateYupSchema from '../../../components/YupSchema/CreateValidateYupSchema';
import { service } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { getLocalizedPath } from '../../../i18n/functions';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles(theme => ({
    root: {},
    scrollPaper: {
        [theme.breakpoints.down(767)]: {
            alignItems: "baseline"
        }
    },
    button: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 14,
        marginTop: 25,
        padding: '8px 7%',
        border: '1px solid',
        color: 'white',
        backgroundColor: '#a3be28',
        borderColor: '#96b11a',
        '&:hover': {
            backgroundColor: '#96b11a',
            borderColor: '#96b11a',
            boxShadow: 'none'
        },
        [theme.breakpoints.down(767)]: {
            display: 'table',
            margin: theme.spacing(2.5, 'auto', 0),
            width: '100%',
            maxWidth: '150px'
        },
        [theme.breakpoints.down(551)]: {
            maxWidth: '100%'
        }
    },
    progressButtton: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 14,
        marginTop: 25,
        color: '#a3be28'
    },
    paper: {
        maxWidth: '400px',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        border: '0px',
        borderRadius: theme.spacing(1),
        boxShadow: 'none',
        outline: 'none',
        padding: theme.spacing(0, 4)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
}));


const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(0, 2),
        minHeight: '100%',
        overflowY: 'initial',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0),
            '& .Credit-MuiGrid-spacing-xs-3': {
                width: "100%",
                margin: 0
            }
        }
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: theme.spacing(0, 0, 2),
        padding: theme.spacing(1),
        justifyContent: 'center'
    }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('480'));
    const { t, i18n } = useTranslation('app');
    const classes = useStyles();

    const initialValues = {
        vehicle: props.category ? props.category : 'car'
    };

    const [redirectToPath, setRedirectToPath] = React.useState(false);

    React.useEffect(() => {
        if (redirectToPath) {
            const timer = setTimeout(() => window.location.replace(redirectToPath), 1500);
            return () => clearTimeout(timer);
        }
    }, [redirectToPath]);

    const [alertSeverity, setAlertSeverity] = React.useState("error");

    const onSubmit = async values => {
        let submitError = {};
        await service.post(getLocalizedPath('create-requirement-loan', i18n), values)
            .then(response => {
                const data = response.data
                if (data.code == 'success') {
                    setAlertSeverity('success');
                    setRedirectToPath(data.data.redirect)
                    submitError = data.message;
                } else {
                    submitError = data.message
                }
            })
            .catch(err => {
                console.log(err);
                submitError = "Something went wrong.";
            });
        return { [FORM_ERROR]: submitError };
    }

    const validateYupSchema = CreateValidateYupSchema([
        'vehicle',
        'partner',
        'autoLoanType'
    ]);

    const required = validateYupSchema.required;

    return (
        <>
            <Dialog
                fullScreen= {fullScreen}
                className={classes.root}
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                scroll="body"
            >
                <Form
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    validate={validateYupSchema.validate}
                    render={({ handleSubmit, submitting, submitError, touched }) => (

                        <form onSubmit={handleSubmit} noValidate>
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={props.handleClose}
                            >
                                <CloseIcon />
                            </IconButton>

                            <DialogContent className={classes.paper}>
                                <ModalContent required={required} />
                            </DialogContent>
                            <DialogActions position="static">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className={classes.button}
                                    disabled={submitting}
                                >
                                    {t('req-model-Continue With', 'Continue with')} {t('req-model-Loan Application', 'Loan Application')}
                                </Button>
                                {submitting && <CircularProgress className={classes.progressButtton} size={27}/> }
                            </DialogActions>
                            {submitError && touched && (
                                <Alert severity={alertSeverity}>{submitError}</Alert>
                            )}
                        </form>
                    )}
                />
            </Dialog>
        </>
    );
}

CustomizedDialogs.propTypes = {
    category: PropTypes.oneOfType([
        PropTypes.oneOf(['car', 'motorcycle/bike', 'scooter']),
        PropTypes.bool
    ]).isRequired,

    open: PropTypes.bool.isRequired,

    handleClickOpen: PropTypes.func.isRequired,

    handleClose: PropTypes.func.isRequired
}
