import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PrimaryButton from './PrimaryButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Container, Grid, Typography, Tab, Tabs } from '@material-ui/core';
import Content from './Content.json';
import { useTranslation } from 'react-i18next';
import { getLocalizedPath } from '../../../i18n/functions';

const MainTabs = withStyles({
    root: {
        borderBottom: 0
    },
    indicator: {
        background: 'transparent'
    }
})(Tabs);
const ChildTab = withStyles(theme => ({
    root: {
        position: 'relative',
        minWidth: 180,
        minHeight: 40,
        marginRight: 10,
        marginBottom: '20px',
        overflow: 'visible',
        borderRadius: 5,
        background: '#fff',
        border: '1px solid #e53c53',
        color: '#e53c53',
        textTransform: 'none',
        '&$selected': {
            color: '#fff',
            background: '#045097',
            border: '1px solid transparent',
            '&$selected:after': {
                content: '""',
                position: 'absolute',
                left: '50%',
                bottom: '-10px',
                transform: 'translateX(-50%)',
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderTop: '10px solid #045097'
            }
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '125px',
            margin: theme.spacing(0, 1.25)
        }
    },
    selected: {}
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'left',
        padding: 20,
        '& div.customGrid': {
            padding: 0,
        }
    },
    text: {
        fontSize: 15,
        display: 'inline-block',
        lineHeight: '1.3',
        verticalAlign: 'super',
        color: '#525252',
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.spacing(1.5)
        }
    },
    iconSm: {
        maxWidth: '100%'
    },
    itemIcon: {
        minWidth: 45,
        height: 22
    },
    customGrid: {
        display: 'flex',
        textAlign: 'left'
    },
    tabContent: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        color: 'black'
    },
    buttonCenter: {
        textAlign: 'center'
    }
}));

export default function BenefitTabs(props) {
    const { t, i18n } = useTranslation(['app', 'common']);
    const [value, setValue] = React.useState(0);
    const classes = useStyles();
    const handleTabs = (e, val) => {
        setValue(val);
    };
    const creditBorrowersData = Content.creditContent.benefitListsforBorrowers;
    const creditLenderData = Content.creditContent.benefitListsforLenders;
    const isLoggedIn = props.isLoggedIn;
    return (
        <>
            <MainTabs
                value={value}
                onChange={handleTabs}
                centered
                className={classes.commonTab}
            >
                <ChildTab label={t('For Borrowers', 'For Borrowers')}></ChildTab>
                <ChildTab label={t('For Lenders', 'For Lenders')}></ChildTab>
            </MainTabs>
            <div className={classes.root}>
                <TabPanel value={value} index={0}>
                    <Container fixed>
                        <Grid container spacing={2}>
                            {creditBorrowersData.map((item, index) => (
                                <Grid item xs={12} sm={6} key={index} className={classes.customGrid}>
                                    <ListItemIcon className={classes.itemIcon}>
                                        <img className={classes.iconSm} src={item.icon} alt={item.text} />
                                    </ListItemIcon>
                                    <Typography className={classes.text}> {t(item.translationKey, item.text)} </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Container fixed>
                        <Grid container spacing={2}>
                            {creditLenderData.map((item, index) => (
                                <Grid item xs={12} sm={6} key={index} className={classes.customGrid}>
                                    <ListItemIcon className={classes.itemIcon}>
                                        <img className={classes.iconSm} src={item.icon} alt={item.text} />
                                    </ListItemIcon>
                                    <Typography className={classes.text}> {t(item.translationKey, item.text)} </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </TabPanel>
            </div>
            {
                value ?
                    !isLoggedIn &&
                    (<div className={classes.buttonCenter}>
                        <PrimaryButton href={getLocalizedPath('lenders', i18n)} name={t('common:Sign up now', 'Sign Up Now')}></PrimaryButton>
                    </div>)
                    :
                    <div className={classes.buttonCenter}>
                        <PrimaryButton href={getLocalizedPath('apply-loan', i18n)} name={t('Apply for Loan', 'Apply For loan')}></PrimaryButton>
                    </div>
            }
        </>
    );
}

function TabPanel(props) {
    const classes = useStyles();
    const { children, value, index } = props;
    return (
        <div className={classes.tabContent}>
            {value === index && <div>{children}</div>}
        </div>
    );
}
