import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RequirementForm from './RequirementForm';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    myRequirements: {
        padding: theme.spacing(4),
        marginTop: theme.spacing(-5),
        borderRadius: theme.spacing(0.5),
        background: '#fff',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(0),
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1.25, 'auto'),
            width: 'initial',
            padding: theme.spacing(3)
        }
    },
    myRequirementstext: {
        fontWeight: '600',
    },
    formSection: {
        '& > *': {
            margin: theme.spacing(1),
            width: '30ch'
        }
    }
}));
export default function MyRequirement(props) {
    const { t, i18n } = useTranslation('app');
    const classes = useStyles();
    return (
        <div className={'container'}>
            <Box className={classes.myRequirements} boxShadow={3}>
                <Typography variant="h6" component="h2" gutterBottom  className={classes.myRequirementstext}>
                    {t('My Credit Requirements', 'My Credit Requirements')}{' '}
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {t('You’re just few minutes away from getting your loan', 'You’re just few minutes away from getting your loan!')}!
        </Typography>
                <RequirementForm category={props.category} requirementFormFocus={props.requirementFormFocus} focusRequirementForm={value => props.focusRequirementForm(value)}/>
            </Box>
        </div>
    );
}

MyRequirement.propTypes = {
    category: PropTypes.oneOfType([
        PropTypes.oneOf(['car', 'motorcycle/bike', 'scooter']),
        PropTypes.bool
    ]).isRequired,
    requirementFormFocus: PropTypes.bool.isRequired,
    focusRequirementForm: PropTypes.func.isRequired
}
