import React from 'react';
import { withStyles, Button } from '@material-ui/core';

const SubmitButton = withStyles(theme => ({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 14,
        marginTop: 25,
        padding: '8px 7%',
        border: '1px solid',
        color: 'white',
        backgroundColor: '#a3be28',
        borderColor: '#96b11a',
        '&:hover': {
            backgroundColor: '#96b11a',
            borderColor: '#96b11a',
            boxShadow: 'none'
        },
        [theme.breakpoints.down(767)]: {
            display: 'table',
            margin: theme.spacing(2.5, 'auto', 0),
            width: '100%',
            maxWidth: '150px'
        }
    }
}))(Button);
export default function PrimaryButton(props) {
    return (
        <SubmitButton variant="contained" color="primary" href={props.href}>
            {props.name}
        </SubmitButton>
    );
}
