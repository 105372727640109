import React from 'react';
import PrimaryTitle from './PrimaryTitle';
import BenefitTabs from './BenefitTabs';
import { useTranslation } from 'react-i18next';

const Benefits = (props) => {
    const { t, i18n } = useTranslation('app');
    return (
        <div className="container">
            <PrimaryTitle name={t('Benefits of Droom Credit', 'Benefits Of Droom Credit')} />
            <BenefitTabs {...props} />
        </div>
    );
}

export default Benefits;