import React from 'react';
import { Typography, Grid, Box, Hidden } from '@material-ui/core';
import ProductDetails from './ProductDetails';
import ActionButtons from './ActionButtons';
import MyRequirement from './MyRequirement';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import vehiclesIcon from '../../../../assets/images/vehicles-img-new.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme =>
    createStyles({
        mainBanner: {
            minHeight: 600,
            marginTop: theme.spacing(7),
            backgroundImage: 
                'url(https://cdn2.droom.in/imgusr/droomCredit/v2/banner-web.png)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(2),
                marginTop: theme.spacing(2),
                minHeight: '100%'
            }
        },
        container: {
            margin: theme.spacing(0)
        },
        mainHeading: {
            color: 'white',
            fontSize: theme.spacing(3.5),
            marginBottom: theme.spacing(2.5),
            [theme.breakpoints.down('md')]: {
                fontSize: theme.spacing(2.75),
                fontWeight: 600,
                marginTop: theme.spacing(2)
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(0.5),
                marginBottom: theme.spacing(0.5)
            },
            '& sup': {
                fontSize: theme.spacing(1.75)
            }
        },
        vehicleImage: {
            position: 'absolute',
            right: 0,
            top: '10%',
            width: 'auto',
            marginTop: theme.spacing(14),
            [theme.breakpoints.down('md')]: {
                right: 0,
                width: 375
            }
        }
    })
);

export default function MainBanner() {
    const { t, i18n } = useTranslation('app');
    const [category, setCategory] = React.useState(false);
    const [requirementFormFocus, setRequirementFormFocus] = React.useState(false);

    const focusRequirementForm = (value) => setRequirementFormFocus(value);

    const handleActionButtonClick = value => {
        focusRequirementForm(true);
        return setCategory(value);
    }

    const classes = useStyles();
    return (
        <div className="root">
            <div className={classes.mainBanner}>
                <Grid container className={'container'}>
                    <Grid item lg={10} xs={12}>
                        <Box paddingTop={4}>
                            <Typography
                                variant="h4"
                                component="h1"
                                className={classes.mainHeading}
                            >
                                21<sup>st</sup> {t('Century Automobile Lending', 'Century Automobile Lending')}
                            </Typography>
                            <ProductDetails />
                            <ActionButtons handleActionButtonClick={value => handleActionButtonClick(value)} />
                        </Box>
                    </Grid>
                </Grid>
                <Hidden smDown>
                    <div>
                        <img
                            src={vehiclesIcon}
                            alt="Droom Credit | Vehicles Icon"
                            className={classes.vehicleImage}
                        />
                    </div>
                </Hidden>
            </div>
            <MyRequirement category={category} requirementFormFocus={requirementFormFocus} focusRequirementForm={value => focusRequirementForm(value)} />
        </div>
    );
}
