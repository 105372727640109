import React from 'react';
import { withStyles, Typography } from '@material-ui/core';

const PrimaryTitle = withStyles(theme => ({
    root: {
        position: 'relative',
        fontSize: theme.spacing(3),
        fontWeight: 600,
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3.75),
        textAlign: 'center',
        '&:after': {
            content: `" "`,
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: '-10px',
            width: 80,
            height: 4,
            background: '#2f98fb'
        },
        [theme.breakpoints.down(767)]: {
            fontSize: theme.spacing(2.5),
            marginTop: theme.spacing(3)
        }
    }
}))(Typography);
export default function PrimaryTitles(props) {
    return (
        <PrimaryTitle component="h3" gutterBottom>
            {props.name}
        </PrimaryTitle>
    );
}
